import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

interface RequestConfig<Data> extends AxiosRequestConfig<Data> {
  url: string
}

export interface ApiResponse<T> extends AxiosResponse<T> {}

export const API_ERROR_MESSAGE = 'API Client Error'

/**
 * Creates a CSRF Header that is independent of the underlying API Client
 * @param headerObj The axios header object.
 * @param htmlDocument The document object.
 * @returns Updated header object with the CSRF token header added
 */
export const addCsrfHeader = (headerObj: RequestConfig<any>['headers'] = {}, htmlDocument: Document = document): RequestConfig<any>['headers'] => {
  const tokenElement = htmlDocument.querySelector('[name=csrf-token]')
  if (tokenElement !== null) {
    headerObj['X-CSRF-TOKEN'] = (tokenElement as HTMLMetaElement).content
  }
  return headerObj
}

/**
 * GET request
 * @param request The axios request config.
 * @returns A promise resolving to the axios response.
 */
const get = async <Data = unknown, Response = unknown>(request: RequestConfig<Data>): Promise<AxiosResponse<Response>> => {
  return await httpFetch({ method: 'get', ...request })
}

/**
 * DELETE request
 * @param request The axios request config.
 * @returns A promise resolving to the axios response.
 */
const httpDelete = async <Data = unknown, Response = unknown>(request: RequestConfig<Data>): Promise<AxiosResponse<Response>> => {
  // httpDelete because `delete` cannot be used as a variable name.
  return await httpFetch({ method: 'delete', ...request })
}

/**
 * DELETE request with CSRF Token added to the header
 * @param request The axios request config.
 * @returns A promise resolving to the axios response.
 */
const deleteWithCsrf = async <Data = unknown, Response = unknown>(request: RequestConfig<Data>): Promise<AxiosResponse<Response>> => {
  request.headers = addCsrfHeader(request.headers, document)
  return await httpDelete(request)
}

/**
 * PATCH request
 * @param request The axios request config.
 * @returns A promise resolving to the axios response.
 */
const patch = async <Data = unknown, Response = unknown>(request: RequestConfig<Data>): Promise<AxiosResponse<Response>> => {
  return await httpFetch({ method: 'patch', ...request })
}

/**
 * PATCH request with CSRF Token added to the header
 * @param request The axios request config.
 * @returns A promise resolving to the axios response.
 */
const patchWithCsrf = async <Data = unknown, Response = unknown>(request: RequestConfig<Data>): Promise<AxiosResponse<Response>> => {
  request.headers = addCsrfHeader(request.headers, document)
  return await patch(request)
}

/**
 * POST request
 * @param request The axios request config.
 * @returns A promise resolving to the axios response.
 */
const post = async <Data = unknown, Response = unknown>(request: RequestConfig<Data>): Promise<AxiosResponse<Response>> => {
  return await httpFetch({ method: 'post', ...request })
}

/**
 * POST request with CSRF Token added to the header
 * @param request The axios request config.
 * @returns A promise resolving to the axios response.
 */
const postWithCsrf = async <Data = unknown, Response = unknown>(request: RequestConfig<Data>): Promise<AxiosResponse<Response>> => {
  request.headers = addCsrfHeader(request.headers, document)
  return await post(request)
}

/**
 * PUT request
 * @param request
 * @returns A promise resolving to the axios response.
 */
const put = async <Data = unknown, Response = unknown>(request: RequestConfig<Data>): Promise<AxiosResponse<Response>> => {
  return await httpFetch({ method: 'put', ...request })
}

/**
 * PUT request with CSRF Token added to the header
 * @param request The axios request config.
 * @returns A promise resolving to the axios response.
 */
const putWithCsrf = async <Data = unknown, Response = unknown>(request: RequestConfig<Data>): Promise<AxiosResponse<Response>> => {
  request.headers = addCsrfHeader(request.headers, document)
  return await put(request)
}

const httpFetch = async <Data = unknown, Response = unknown>(request: AxiosRequestConfig<Data>): Promise<AxiosResponse<Response>> => {
  try {
    return await axios(request)
  } catch (e) {
    // Provide some useful information to help debug the error/unhandled rejection
    // even if the error is unhandled or the stack trace is not useful (for example a bad source map)
    console.error(`${API_ERROR_MESSAGE}: ${request?.method?.toUpperCase() ?? ''} ${request?.url ?? ''}`)
    throw e
  }
}

// Create an API Client that abstracts the client implementation from the rest of our code
export const api = {
  delete: httpDelete,
  deleteWithCsrf,
  get,
  patch,
  patchWithCsrf,
  post,
  postWithCsrf,
  put,
  putWithCsrf
}
